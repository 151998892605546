<template>
    <div class="root">
        <form v-on:submit.prevent="submitSearch">
            <input v-model="searchTerm" type="text" placeholder="Enter Your search word here"/>
            <button type="submit">Search</button>
        </form>
        <div class="image-block" id="imageBlock" @mousemove="updateMouseCords( $event )" ref="imageBlock">
            <div 
                v-for="image in images" 
                :key="image.index" 
                :style="'background-image: URL(' + image.url + '); width:' + thbWidth + 'px; height:' + thbHeight + 'px;'"
                @click="selectImage(image.id, image.description, image.url)"
                @mouseover="mouseoverImage(image.id, image.description, image.url)"
                @mouseleave="mouseleaveImage()"
                class="image-preview" 
            >
            </div>
            <button @click="showMore" v-if="images.length > 0 && ! gettingMore" class="more-button">More</button>
        </div>
        <div class="hover-preview" ref="preview" @mouseover="$refs.preview.style.display = 'none'">
            <div>
                <div  
                    :style="'background-image: URL(' + previewImage.url + '); width:' + ( thbWidth * 2 ) + 'px; height:' + ( thbHeight * 2 ) + 'px; margin: auto'"
                    class="image-preview" 
                >
                </div>
            </div>
            <div v-html="previewImage.id"></div>
            <div v-html="previewImage.description"></div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
    data: function(){
        return{
            searchTerm: '',
            images: [],
            page: '1',
            gettingMore: false,
            previewImage: {
                url: '',
                id: '',
                description: ''
            },
            mouseCords: {
                x: 0,
                y: 0
            }
        }
    },
    methods:{
        submitSearch: function(){
            this.images = []
            this.searchImages()
        },
        searchImages: function(){

            this.gettingMore = true

            var page = this.page

            var formData = new FormData();
            formData.append('searchString', this.searchTerm )
            formData.append('page', page)
            formData.append('json', '1')

            this.axios.post( 'https://www.bigblinds.co.uk/search/results.php', formData).then(response => {

                if( Array.isArray(response.data.url) )
                {

                    var images = this.images

                    var data = response.data

                    var dataCount = data.url.length

                    for( var i = 0; i < dataCount; i++ )
                    {

                        images.push({
                            url: data.url[i],
                            description: data.disc[i],
                            id: data.id[i]
                        })

                    }

                    this.images = images

                }

            }, response => {
            
                console.error( response )

            }).then( () =>{
                console.log( document.getElementById('imageBlock').getBoundingClientRect().height )

                document.getElementById('app').setAttribute("style", "height: " + ( document.getElementById('imageBlock').getBoundingClientRect().height + 450 ) + 'px' )
            } );

            this.gettingMore = false
            
        },
        showMore: function(){

            this.page = ( parseInt(this.page) + 1 ).toString()

            if( this.page < 10 )
            {
                this.searchImages()
            }
        },
        selectImage: function(id, description, url)
        {
            var payload = {
                id: id,
                description: description,
                url: url
            }

            this.$store.commit( 'setStockImage', payload )

            this.$store.commit( 'addProductToBasket' )

            this.$router.push( '/basket' )
            
        },
        mouseoverImage:function(id, description, url){
            this.previewImage.url = url
            this.previewImage.id = id
            this.previewImage.description = description 
            this.$refs.preview.style.display = 'block'

            var payload = {
                id: id,
                description: description,
                url: url
            }

            this.$store.commit( 'setStockImage', payload )
        },
        mouseleaveImage:function(){
            this.$refs.preview.style.display = 'none'
        },
        updateMouseCords:function( event ){
            this.mouseCords.x = event.clientX
            this.mouseCords.y = event.clientY

            if( window.innerWidth > ( this.$refs.preview.clientWidth + this.mouseCords.x + 20)   )
            {
                this.$refs.preview.style.left = (this.mouseCords.x + 20) + 'px'
            }else {
                this.$refs.preview.style.left = (this.mouseCords.x - this.$refs.preview.clientWidth - 20) + 'px'
            }

            if( window.innerHeight > ( this.$refs.preview.clientHeight + this.mouseCords.y + 20)   )
            {
                this.$refs.preview.style.top = (this.mouseCords.y + 20) + 'px'
            }else {
                this.$refs.preview.style.top = (this.mouseCords.y - this.$refs.preview.clientHeight - 20) + 'px'
            }
            
        },
        ...mapMutations([
            'setStockImage',
            'addProductToBasket'
        ])
    },
    computed:{
        thbHeight:function(){
            var height = parseInt(this.$store.state.currentProduct.height)
            var width = parseInt(this.$store.state.currentProduct.width)

            console.log( 'Height: ' + height )
            console.log( 'Width: ' + width )

            if( parseInt(height) < parseInt(width) ){
                console.log(( height / width ) * 150)
                return ( height / width ) * 150
            } else {
                return 150;
            }
            
        },
        thbWidth:function(){
            var height = parseInt(this.$store.state.currentProduct.height)
            var width = parseInt(this.$store.state.currentProduct.width)

            console.log( 'Height: ' + height )
            console.log( 'Width: ' + width )

            if( parseInt(height) > parseInt(width) ){
                console.log(( width / height ) * 150)
                return ( width / height ) * 150
            } else {
                return 150;
            }
        },
        ...mapGetters([
            'getCurrentProduct',
            'getBasket'
        ])
    },
    mounted:function(){

        /**
         * Check to see if product has been selected by seeing if price is 0
         */
        if( this.getCurrentProduct.cost == 0 )
        {
            this.$router.push( '/' )
        }

        this.$store.commit( 'disablePopularSearches' )
        this.searchImages()
    }

}
</script>

<style scoped>

    .root{
        text-align: center;
    }

    input, button{
        font-size: 2rem;
        padding: 1rem;
        margin: 2rem 0;
    }

    input{
        width: 30rem;
    }

    button{
        font-size: 2rem;
        border: 1px solid white;
        background-color: white;
        font-weight: 700;
    }

    .image-preview{
        background-size: cover;   
        background-position: center;
        display: block;
        margin: 1rem;
        border: 2px solid white;
        
    }

    .image-block{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    .more-button{
        width: 100%;
    }

    .hover-preview{
        position: fixed;
        left: 50%;
        top: 50%;
        background-color: white;
        padding: 1rem;
        border: 1px solid black;
        box-shadow: 1rem 1rem 1.5rem rgba(0, 0, 0, 0.25);
        max-width: 350px;
        display: none;
    }
</style>